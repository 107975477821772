import { IPropertyRepository } from "../../../domain/repositories/PropertyRepository";
import { PropertyEntity } from "../../../domain/entities/PropertyEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class UpdateProperty {
  private propertyRepository: IPropertyRepository;

  constructor(propertyRepository: IPropertyRepository) {
    this.propertyRepository = propertyRepository;
  }

  async execute(
    propertyId: number,
    propertyData: PropertyEntity
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    return this.propertyRepository.updateProperty(propertyId, propertyData);
  }
}
