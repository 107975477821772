import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { MediaEntity } from "../entities/MediaEntity";

import { IUploadResponse } from "../entities/UploadResponseEntity";

// Supabase client
import { supabaseClient } from "App";

export interface IPhotoRepository {
  fetchPhotos(propertyId: number): Promise<PostgrestSingleResponse<MediaEntity[] | null>>;
  deleteMedia(
    mediaId: number,
    mediaPath: string
  ): Promise<PostgrestSingleResponse<MediaEntity | null>>;
  uploadMedia(mediaId: number, file: File): Promise<IUploadResponse>;
}

export class PhotoRepository implements IPhotoRepository {
  async fetchPhotos(propertyId: number): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    return await supabaseClient
      .from("property_media")
      .select("*")
      .eq("property_id", propertyId)
      .eq("media_type", "image");
  }

  async deleteMedia(
    mediaId: number,
    mediaPath: string
  ): Promise<PostgrestSingleResponse<MediaEntity | null>> {
    await supabaseClient.storage.from("new-public-bucket").remove([mediaPath]);

    return await supabaseClient.from("property_media").delete().eq("id", mediaId);
  }

  async uploadMedia(propertyId: number, file: File): Promise<IUploadResponse> {
    const uploadResponse: IUploadResponse = await supabaseClient.storage
      .from("new-public-bucket")
      .upload(file.name, file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (uploadResponse.error == null) {
      const getPublicUrlResponse = await supabaseClient.storage
        .from("new-public-bucket")
        .getPublicUrl(uploadResponse.data?.path!);

      await supabaseClient.from("property_media").insert({
        media_url: getPublicUrlResponse.data.publicUrl,
        media_path: uploadResponse.data?.path!,
        media_type: "image",
        property_id: propertyId,
      });
    }

    return uploadResponse;
  }
}
