import { IPropertyRepository } from "../../../domain/repositories/PropertyRepository";
import { PropertyEntity } from "../../../domain/entities/PropertyEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetPropertyDetails {
  private propertyRepository: IPropertyRepository;

  constructor(propertyRepository: IPropertyRepository) {
    this.propertyRepository = propertyRepository;
  }

  async execute(propertyId: number): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    return this.propertyRepository.fetchData(propertyId);
  }
}
