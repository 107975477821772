import { IVideoRepository } from "./IVideoRepository";
import { MediaEntity } from "../entities/MediaEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

// Supabase client
import { supabaseClient } from "App";

export class VideoRepository implements IVideoRepository {
  async fetchVideos(propertyId: number): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    return await supabaseClient
      .from("property_media")
      .select("*")
      .eq("property_id", propertyId)
      .eq("media_type", "video");
  }
}
