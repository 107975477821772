import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "modules/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic2.jpg";

import logoImage from "assets/images/logo_snap_tipografico.png";

// Utils
import LocalStorageManager from "utils/LocalStorageManager";

// Supabase client
import { supabaseClient } from "App";

function Basic() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const loginUser = async (e) => {
    /* TODO(@Marcus-Oliveira: Adicionar um loading na tela e um forma de armazenar os dados da sessão */
    const { data, error } = await supabaseClient.auth.signInWithPassword({
      email: username,
      password: password,
    });
    LocalStorageManager.setValue("session", JSON.stringify(data["session"]));
    window.location.href = "/properties";
  };

  useEffect(() => {
    LocalStorageManager.clear();
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox
            component="img"
            src={logoImage}
            alt="Brand"
            width="14rem"
            sx={{
              marginTop: "16px",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Inciar sessão
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setUserName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={loginUser}>
                Continuar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
