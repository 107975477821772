// Supabase client
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { ICompanyEntity } from "../entities/CompanyEntity";
import { supabaseClient } from "App";

export interface ICompanyRepository {
  getCompnayData(userId: number): Promise<PostgrestSingleResponse<ICompanyEntity | null>>;
  updateCompanyData(
    userId: number,
    companyData: ICompanyEntity
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>>;
}

export class CompanyRepository implements ICompanyRepository {
  async getCompnayData(userId: number): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return await supabaseClient.from("company").select("*").eq("user_id", userId).single();
  }

  async updateCompanyData(
    companyId: number,
    companyData: ICompanyEntity
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    return await supabaseClient.from("company").update(companyData).eq("id", companyId);
  }
}
