// Importing React components
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Components of Properties Module
import ListVideos from "modules/properties/pages/details/components/ListVideos";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

function VideosTab(props) {
  const { propertyId, ...other } = props;

  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchErrorMessage = "Ocorreu um erro";

  const handleRebuild = () => {
    getVideos();
  };

  const getVideos = async () => {
    const detailsController = new DetailsController();
    setLoading(true);
    try {
      const { data, error } = await detailsController.fetchVideos(propertyId);
      if (error) {
        setError(fetchErrorMessage);
      } else if (data) {
        setVideos(data);
      }
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideos();
  }, [propertyId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return <ListVideos videosList={videos} requestParentRebuild={handleRebuild} />;
}

VideosTab.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default VideosTab;
