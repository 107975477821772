import { PropertyEntity } from "../entities/PropertyEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

// Supabase client
import { supabaseClient } from "App";

export interface IPropertyRepository {
  fetchData(propertyId: number): Promise<PostgrestSingleResponse<PropertyEntity | null>>;
  deletePropertyById(propertyId: number): Promise<PostgrestSingleResponse<PropertyEntity | null>>;
  getAllProperties(): Promise<PostgrestSingleResponse<PropertyEntity[] | null>>;
  updateProperty(
    propertyId: number,
    propertyData: PropertyEntity
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>>;
}

export class PropertyRepository implements IPropertyRepository {
  async updateProperty(
    propertyId: number,
    propertyData: PropertyEntity
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    return await supabaseClient.from("property").update(propertyData).eq("id", propertyId);
  }

  async fetchData(propertyId: number): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    return await supabaseClient.from("property").select("*").eq("id", propertyId).single();
  }

  async getAllProperties(): Promise<PostgrestSingleResponse<PropertyEntity[] | null>> {
    return await supabaseClient.from("property").select("*");
  }

  async deletePropertyById(
    propertyId: number
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    return await supabaseClient.from("property").delete().eq("id", propertyId);
  }
}
