import { IPhotoRepository } from "../../../domain/repositories/PhotoRepository";
import { MediaEntity } from "../../../domain/entities/MediaEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetPhotos {
  private photoRepository: IPhotoRepository;

  constructor(photoRepository: IPhotoRepository) {
    this.photoRepository = photoRepository;
  }

  async execute(propertyId: number): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    return this.photoRepository.fetchPhotos(propertyId);
  }
}
