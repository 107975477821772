import { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import DataTable from "examples/Tables/DataTable";

// Supabase client
import { supabaseClient } from "App";

function UsersTab({ companyId }) {
  let columns = [
    { Header: "name", accessor: "name", width: "45%", align: "left" },
    { Header: "function", accessor: "function", align: "left" },
  ];
  let response = null;

  const [newRows, setNewRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      response = await _getUsersData(companyId);
      setNewRows(formatRowData(response));
    }
    fetchData();
  }, []);

  return (
    <DataTable
      table={{ columns: columns, rows: newRows }}
      isSorted={true}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}

async function _getUsersData(companyId) {
  /* TODO(@Marcus-Oliveira: Pegando os dados da tabela AssociatedUser. REFATORAR em outra camada */
  let finalData = [];
  if (companyId != null) {
    const { data: dataUD, error: errorUD } = await supabaseClient
      .from("associated_user")
      .select()
      .eq("company_id", companyId);
    finalData = dataUD;
  }
  return finalData;
}

function formatRowData(rowData) {
  return rowData.map((row) => {
    return {
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.user_name}
        </MDTypography>
      ),
      function: _buildRoleInCompanyComponet(row.role),
    };
  });
}

function _buildRoleInCompanyComponet(role) {
  let roleInCompanyComponent;

  if (role == "company_admin") {
    roleInCompanyComponent = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Administrador
      </MDTypography>
    );
  } else {
    roleInCompanyComponent = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        Usuário comum
      </MDTypography>
    );
  }

  return roleInCompanyComponent;
}

// Typechecking props for the UsersTab
UsersTab.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default UsersTab;
