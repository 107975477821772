// importing entities
import { MediaEntity } from "../../../domain/entities/MediaEntity";
import { PropertyEntity } from "../../../domain/entities/PropertyEntity";

// importing usescases
import { GetVideos } from "../usecases/GetVideos";
import { GetPhotos } from "../usecases/GetPhotos";
import { GetPropertyDetails } from "../usecases/GetPropertyDetails";
import { GetAllProperties } from "../usecases/GetAllProperties";
import { DeleteProperty } from "../usecases/DeleteProperty";
import { DeleteMedia } from "../usecases/DeleteMedia";
import { UpdateProperty } from "../usecases/UpdateProperty";
import { UploadMedia } from "../usecases/UploadMedia";

// importing repositories
import { VideoRepository } from "../../../domain/repositories/VideoRepository";
import { PhotoRepository } from "modules/properties/domain/repositories/PhotoRepository";
import { PropertyRepository } from "modules/properties/domain/repositories/PropertyRepository";

// importing supabase references
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class DetailsController {
  private getVideosUC: GetVideos;
  private getPhotosUC: GetPhotos;
  private getPropertyDetailsUC: GetPropertyDetails;
  private getAllPropertiesUC: GetAllProperties;
  private deletePropertyUC: DeleteProperty;
  private deleteMediaUC: DeleteMedia;
  private updatePropertyUC: UpdateProperty;
  private uploadMediaUC: UploadMedia;

  constructor() {
    const videoRepository = new VideoRepository();
    const photoRepository = new PhotoRepository();
    const propertyRepository = new PropertyRepository();

    this.getVideosUC = new GetVideos(videoRepository);
    this.getPhotosUC = new GetPhotos(photoRepository);
    this.getPropertyDetailsUC = new GetPropertyDetails(propertyRepository);
    this.getAllPropertiesUC = new GetAllProperties(propertyRepository);
    this.deletePropertyUC = new DeleteProperty(propertyRepository);
    this.deleteMediaUC = new DeleteMedia(photoRepository);
    this.updatePropertyUC = new UpdateProperty(propertyRepository);
    this.uploadMediaUC = new UploadMedia(photoRepository);
  }

  public async getProperties(): Promise<PostgrestSingleResponse<PropertyEntity[] | null>> {
    try {
      const response = await this.getAllPropertiesUC.execute();
      return response;
    } catch (error) {
      console.error("Error fetching PropertyData:", error);
      throw new Error("Could not fetch PropertyData");
    }
  }

  public async fetchPropertyDetails(
    propertyId: number
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    try {
      const response = await this.getPropertyDetailsUC.execute(propertyId);
      return response;
    } catch (error) {
      console.error("Error fetching PropertyData:", error);
      throw new Error("Could not fetch PropertyData");
    }
  }

  public async fetchVideos(
    propertyId: number
  ): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    try {
      const response = await this.getVideosUC.execute(propertyId);
      return response;
    } catch (error) {
      console.error("Error fetching videos:", error);
      throw new Error("Could not fetch videos");
    }
  }

  public async fetchPhotos(
    propertyId: number
  ): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    try {
      const response = await this.getPhotosUC.execute(propertyId);
      return response;
    } catch (error) {
      console.error("Error fetching photos:", error);
      throw new Error("Could not fetch photos");
    }
  }

  public async deletePropertyByID(
    propertyId: number
  ): Promise<PostgrestSingleResponse<PropertyEntity | null>> {
    try {
      const response = await this.deletePropertyUC.execute(propertyId);
      return response;
    } catch (error) {
      console.error("Error deleting Property:", error);
      throw new Error("Error deleting Property");
    }
  }

  public async deleteMedia(
    mediaId: number,
    mediaPath: string
  ): Promise<PostgrestSingleResponse<MediaEntity | null>> {
    try {
      const response = await this.deleteMediaUC.execute(mediaId, mediaPath);
      return response;
    } catch (error) {
      console.error("Error deleting Property:", error);
      throw new Error("Error deleting Property");
    }
  }

  public async updateProperty(propertyId: number, objectData: PropertyEntity): Promise<any> {
    try {
      const propertyData: any = {
        title: objectData.title,
        description: objectData.description,
        number_of_rooms: objectData.number_of_rooms,
        street_address: objectData.street_address,
        city_address: objectData.city_address,
        state_address: objectData.state_address,
        country_address: objectData.country_address,
      };
      const response = await this.updatePropertyUC.execute(propertyId, propertyData);
      return response;
    } catch (error) {
      console.error("Error updating Property:", error);
      throw new Error("Error updating Property");
    }
  }

  public async uploadPhoto(propertyId: number, file: File): Promise<any> {
    try {
      const response = await this.uploadMediaUC.execute(propertyId, file);
      return response;
    } catch (error) {
      console.error("Error uploading photo in Property:", error);
      throw new Error("Error uploading photo in Property");
    }
  }
}
