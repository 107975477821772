// importing entities
import { ICompanyEntity } from "modules/company/domain/entities/CompanyEntity";

// importing usescases
import { GetCompanyData } from "modules/company/domain/usecases/GetCompanyData";
import { UpdateCompanyData } from "modules/company/domain/usecases/UpdateCompanyData";

// importing repositories
import { CompanyRepository } from "modules/company/domain/repositories/CompanyRepository";

// importing supabase references
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class CompanyController {
  private getCompanyDataUC: GetCompanyData;
  private updateCompanyDataUC: UpdateCompanyData;

  constructor() {
    const companyRepository = new CompanyRepository();

    this.getCompanyDataUC = new GetCompanyData(companyRepository);
    this.updateCompanyDataUC = new UpdateCompanyData(companyRepository);
  }

  public async getCompanyData(
    userId: number
  ): Promise<PostgrestSingleResponse<ICompanyEntity | null>> {
    try {
      const response = await this.getCompanyDataUC.execute(userId);
      return response;
    } catch (error) {
      console.error("Error fetching companyData:", error);
      throw new Error("Could not fetch companyData");
    }
  }

  public async updateCompany(companyId: number, objectData: ICompanyEntity): Promise<any> {
    try {
      const companyData: any = {
        name: objectData.name,
        website: objectData.website,
      };
      const response = await this.updateCompanyDataUC.execute(companyId, companyData);
      return response;
    } catch (error) {
      console.error("Error updating Company data:", error);
      throw new Error("Error updating Company data");
    }
  }
}
