import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropzone, FileMosaic } from "@files-ui/react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Data
import UserDevicesTab from "modules/company/components/UserDevicesTab";
import UsersTab from "modules/company/components/UsersTab";

// Supabase client
import { supabaseClient } from "App";
import { CompanyController } from "./controller/CompanyController";

// Utils
import LocalStorageManager from "utils/LocalStorageManager";

// Getting Session Data
let sessionValue = JSON.parse(LocalStorageManager.getValue("session"));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CompanyPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchErrorMessage = "Ocorreu um erro";

  const [tabValue, setTabValue] = useState(0);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const [logoFile, setLogoFile] = useState([]);
  const updateLogoFile = (incommingFiles) => {
    setLogoFile(incommingFiles);
  };
  const [waterMarkFile, setWaterMarkFile] = useState([]);
  const updateWaterMarkFile = (incommingFiles) => {
    setWaterMarkFile(incommingFiles);
  };

  const [companyData, setCompanyData] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    website: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    website: "",
  });

  const _getCompanyData = async () => {
    setLoading(true);
    try {
      const companyController = new CompanyController();
      const { data: successResponse, error: errorResponse } =
        await companyController.getCompanyData(sessionValue?.user?.id);
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        setCompanyData(successResponse);
        setFormValues({
          name: successResponse.name,
          website: successResponse.website,
        });
      }
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await _getCompanyData();
    }
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validate = () => {
    let tempErrors = {
      name: "",
      website: "",
    };
    tempErrors.name = formValues.name ? "" : "Nome é obrigatório.";
    tempErrors.website = formValues.website ? "" : "Website é obrigatório.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const _updateCompanyData = async (companyId, companyData) => {
    setLoading(true);
    try {
      const companyController = new CompanyController();
      const { data: successResponse, error: errorResponse } = await companyController.updateCompany(
        companyId,
        formValues
      );
      if (error) {
        setError(fetchErrorMessage);
      } else if (successResponse) {
        setCompanyData({ ...companyData, ...formValues });
      }
      setLoading(false);
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      await _updateCompanyData(companyData.id, companyData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h3" color="white">
                  Empresa
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <AppBar position="static">
                  <MDBox pl={2} pr={2}>
                    <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                      <Tab
                        label="Informações gerais"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            info
                          </Icon>
                        }
                      />
                      <Tab
                        label="Usuários"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            person
                          </Icon>
                        }
                      />
                      <Tab
                        label="Dispositivos"
                        icon={
                          <Icon fontSize="small" sx={{ mt: -0.25 }}>
                            smartphone
                          </Icon>
                        }
                      />
                    </Tabs>
                  </MDBox>
                </AppBar>
                <CustomTabPanel value={tabValue} index={0}>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    CompanyProfileInfo(
                      formValues,
                      handleInputChange,
                      handleSubmit,
                      errors,
                      updateLogoFile,
                      updateWaterMarkFile,
                      logoFile,
                      waterMarkFile
                    )
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <UsersTab companyId={companyData?.id} />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  <UserDevicesTab companyId={companyData?.id} />
                </CustomTabPanel>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Fab
          variant="extended"
          color="primary"
          aria-label="save"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          onClick={handleSubmit}
        >
          <SaveIcon sx={{ mr: 1 }} />
          Salvar alterações
        </Fab>
      </MDBox>
    </DashboardLayout>
  );
}

function CompanyProfileInfo(
  formValues,
  handleInputChange,
  handleSubmit,
  errors,
  updateLogoFile,
  updateWaterMarkFile,
  logoFile,
  waterMarkFile
) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
        <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
        <Box
          component="form"
          sx={{
            "& > :not(style)": { marginTop: 2, width: "90%" },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Informações gerais
          </MDTypography>
          <TextField
            id="name"
            value={formValues.name}
            onChange={handleInputChange}
            label="Nome"
            variant="outlined"
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            id="website"
            value={formValues.website}
            onChange={handleInputChange}
            label="Website"
            variant="outlined"
            required
            error={!!errors.website}
            helperText={errors.website}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Logo
        </MDTypography>
        <Dropzone
          onChange={updateLogoFile}
          value={logoFile}
          accept="image/png, image/jpg"
          maxFiles={1}
          maxFileSize={2 * 1024 * 1024}
          localization={"PT-pt"}
          config={{ localization: "PT-pt" }}
        >
          {logoFile.map((file) => (
            <FileMosaic {...file} preview />
          ))}
        </Dropzone>
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <Box pl="35px">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Marca dágua
          </MDTypography>
          <Dropzone
            onChange={updateWaterMarkFile}
            value={waterMarkFile}
            accept="image/png, image/jpg"
            maxFiles={1}
            maxFileSize={2 * 1024 * 1024}
            localization={"PT-pt"}
          >
            {waterMarkFile.map((file) => (
              <FileMosaic {...file} preview />
            ))}
          </Dropzone>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CompanyPage;
