// Importing React components
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import UploadTwoTone from "@mui/icons-material/UploadTwoTone";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Shared Components
import LoadingSpinner from "@components/LoadingSpinner";

// Components of Properties Module
import ListPhotos from "modules/properties/pages/details/components/ListPhotos";

// Importing controller
import { DetailsController } from "modules/properties/pages/details/controller/DetailsController";

function PhotosTab(props) {
  const { propertyId, ...other } = props;

  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const fetchErrorMessage = "Ocorreu um erro";

  const handleRebuild = () => {
    getPhotos();
  };

  const getPhotos = async () => {
    const detailsController = new DetailsController();
    setLoading(true);
    try {
      const { data, error } = await detailsController.fetchPhotos(propertyId);
      if (error) {
        setError(fetchErrorMessage);
      } else if (data) {
        setPhotos(data);
      }
    } catch (error) {
      setError(fetchErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validExtensions = ["image/jpeg", "image/png", "image/jpg"];
    if (!validExtensions.includes(file.type)) {
      setAlertMessage("Por favor, envie uma imagem com a extensão jpg, jpeg ou png.");
      setAlertOpen(true);
      return;
    }

    const detailsController = new DetailsController();
    setLoading(true);
    try {
      const { data, error } = await detailsController.uploadPhoto(propertyId, file);
      if (error) {
        setAlertMessage(fetchErrorMessage);
        setAlertOpen(true);
      } else {
        handleRebuild();
      }
    } catch (error) {
      setAlertMessage(fetchErrorMessage);
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    getPhotos();
  }, [propertyId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <ListPhotos photosList={photos} requestParentRebuild={handleRebuild} />
      <Box style={{ position: "fixed", bottom: 24, right: 32 }}>
        <input
          accept="image/jpeg,image/png,image/jpg"
          style={{ display: "none" }}
          id="upload-photo"
          type="file"
          onChange={handleUpload}
        />
        <label htmlFor="upload-photo">
          <Fab component="span" variant="extended" size="medium" color="primary">
            <UploadTwoTone sx={{ mr: 1 }} />
            Adicionar nova foto
          </Fab>
        </label>
      </Box>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

PhotosTab.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default PhotosTab;
