import { IVideoRepository } from "../../../domain/repositories/IVideoRepository";
import { MediaEntity } from "../../../domain/entities/MediaEntity";
import { PostgrestSingleResponse } from "@supabase/supabase-js";

export class GetVideos {
  private videoRepository: IVideoRepository;

  constructor(videoRepository: IVideoRepository) {
    this.videoRepository = videoRepository;
  }

  async execute(propertyId: number): Promise<PostgrestSingleResponse<MediaEntity[] | null>> {
    return this.videoRepository.fetchVideos(propertyId);
  }
}
